import React from 'react';
import 'tippy.js/dist/tippy.css';
import Image from "next/image";

const SponsoredItem = ({name, website, twitter, description, image}) => {
  return (
    <>
      <a href={website}
         className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl mb-2 relative flex items-center border bg-white p-3 transition-shadow hover:shadow-lg">
        <figure className="mr-5 self-start">
          <Image
            src={image}
            alt={name}
            height={60}
            width={60}
            objectFit="cover"
            className="rounded-2lg"
            loading="lazy"
          />
        </figure>

        <div className="ml-1">
          <h3 className="font-display text-jacarta-700 mb-1 text-base font-semibold dark:text-jacarta-200 text-left">{name}</h3>
          <span className="dark:text-jacarta-200 text-jacarta-500 mb-2 block text-sm text-left max-w-md">
            {description}
          </span>
          <p className="text-jacarta-300 block text-xs text-left">{website} - {twitter}</p>
        </div>

        <div className="text-jacarta-300 text-xs dark:border-jacarta-600 border-jacarta-100 ml-auto rounded-full border p-3">
          SPONSORED
        </div>
      </a>
    </>
  );
};

export default SponsoredItem;
