import React from "react";
import Meta from "../../components/Meta";
import algoliasearch from 'algoliasearch/lite';
import {InstantSearch, SearchBox, Hits, RefinementList} from 'react-instantsearch-dom';
import Item from "./Item";
import SponsoredItem from "./SponsoredItem";

const searchClient = algoliasearch('6P21C684VH', 'a1350063b2f03b2868ffda9d64bc6faa');
const Home = () => {
  return (
    <>
      <Meta title="Tech Conferences 2024"/>

      <section className="dark:bg-jacarta-800 relative mt-20 mb-10">

        <div className="text-center">
          <InstantSearch searchClient={searchClient} indexName="conferences">

            <section className="hero mb-10">
              <div className="container">
                <div className="mx-auto max-w-2xl pt-10 text-center">

                  <h1 className="mb-3 font-display text-4xl text-jacarta-700 dark:text-white lg:text-5xl xl:text-5xl">
                    The best selection of<br/>
                    <span className="animate-gradient">Software & Tech Conferences</span><br/>
                  </h1>

                  <h2 className="mb-10 font-display dark:text-jacarta-200">
                    An accurate list of conferences where you can enjoy and learn from the best Speakers.
                  </h2>

                  <SearchBox
                    className="searchbox w-full mx-auto max-w-2xl text-center dark:text-jacarta-200"
                    translations={{
                      placeholder: 'Search by country, city, category or name of the event',
                    }}
                  />
                </div>
              </div>
            </section>
            <section className="pt-10 mb-10">
              <div className="container">
                <div className="tab-pane fade">
                  <div className="lg:flex">

                    <aside className="basis-4/12 lg:pr-10 hidden md:block">
                      <div className="flex flex-wrap sidebar-filter dark:text-jacarta-200">
                        <h3>Continent:</h3>
                        <RefinementList attribute="continent"/>
                        <h3>Country:</h3>
                        <RefinementList attribute="countryName"/>
                      </div>
                    </aside>

                    <div className="mb-10 shrink-0 basis-8/12 space-y-5 lg:mb-0 lg:pl-5">
                      {/*<SponsoredItem*/}
                      {/*  name="Engineering Book Club"*/}
                      {/*  website="https://www.engineeringbookclub.com"*/}
                      {/*  twitter="@TheEngBookClub"*/}
                      {/*  description="An online community of professionals that enjoy reading and discussing engineering books together."*/}
                      {/*  image="/images/engineering-book-club.jpg"*/}
                      {/*/>*/}
                      <SponsoredItem
                        name="QCon London"
                        website="https://qconlondon.com/"
                        twitter="@QCon"
                        description="An international software development conference for senior software developers to learn emerging patterns, practices, and use cases leveraged by early adopter companies."
                        image="https://www.softwaretalks.io/_next/image?url=https%3A%2F%2Fbackend.softwaretalks.io%2Fimages%2Fconferences%2Fjlgpzt1d-400x400-67433b4804b3b116946264.png&w=128&q=75"
                      />
                      <Hits hitComponent={Item}/>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </InstantSearch>
        </div>
      </section>
    </>
  );
};

export default Home;
